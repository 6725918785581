<template>
	<ej-blank-page class="d-flex align-items-center">
		<div class="container">
			<div class="row d-flex justify-content-center">
				<div class="col-lg-4 col-md-4 col-11 align-self-center">
					<div class="d-flex justify-content-center">
						<img class="img-fluid" :src="appLogo" alt="">
					</div>
				</div>

				<div class="col-lg-4 col-md-6 col-11 align-self-center">
					<h2 class="text-center">{{ appName }}</h2><br>

					<div class="form-group">
						<input
							type="email"
							placeholder="Usuário"
							class="form-control"
							v-model="credentials.username"
							v-on:keyup.enter="login"
						>
					</div>

					<div class="form-group">
						<input
							type="password"
							placeholder="Senha"
							class="form-control"
							v-model="credentials.password"
							v-on:keyup.enter="login"
						>
					</div>

					<div class="form-group">
						<button class="btn btn-primary form-control" @click="login">
							Entrar <font-awesome-icon :icon="['fas', 'cog']" spin v-if="loggingIn"></font-awesome-icon>
						</button>
					</div>

					<div class="form-group text-center">
						<a href="#" @click="sendResetLinkEmail">Esqueci minha senha</a>
					</div>
				</div>
			</div>
		</div>

		<ej-footer></ej-footer>
	</ej-blank-page>
</template>

<script>
	import EjBlankPage from "@/components/EjBlankPage";
	import EjFooter from "@/components/EjFooter";
	import Swal from "sweetalert2";
	import {exibeErro} from "@/helpers";

	export default {
		name: "index",

		data() {
			return {
				loggingIn: false,
				credentials: {
					username: '',
					password: ''
				}
			}
		},

		computed: {
			appName() {
				return process.env.VUE_APP_APP_NAME;
			},

			appLogo() {
				return process.env.VUE_APP_APP_LOGO;
			}
		},

		methods: {
			sendResetLinkEmail() {
				const vm = this;

				Swal.fire({
					title: 'Informe seu e-mail',
					icon: 'info',
					input: 'email',
					inputPlaceholder: 'E-mail',
					confirmButtonText: 'Solicitar',
					showLoaderOnConfirm: true,
					preConfirm: async function(email) {
						try {
							await vm.$store.dispatch(`passwords/request_reset`, {email: email});
						} catch (e) {
							exibeErro(e);
						}
					},
					allowOutsideClick: () => !Swal.isLoading()
				})
					.then(async function(result) {
						if (result.isConfirmed) {
							Swal.fire(
								'Sucesso!',
								'Solicitação enviada com sucesso, verifique seu e-mail!',
								'success'
							);
						}
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
					});
			},

			async login() {
				this.loggingIn = true;
				await this.$store.dispatch(`auth/login`, this.credentials);
				this.loggingIn = false;
			}
		},

		components: {
			EjBlankPage,
			EjFooter
		}
	}
</script>

<style scoped>

</style>
